import { ChangeDetectionStrategy, ChangeDetectorRef, Component } from '@angular/core';
import { IReportEmbedConfiguration, models } from 'powerbi-client';
import { GlobalService } from '../../../../SGRE-shared/services/global.service';
import { ApiService } from '../../../../SGRE-shared/services/api.service';
import { map, Subject, takeUntil } from 'rxjs';
import * as pbi from 'powerbi-client';
import { CmsComponentData } from '@spartacus/storefront';
import { StorageService } from '../../../../SGRE-shared/services/storage.service';
@Component({
  selector: 'app-sales-charts-panel-container',
  templateUrl: './sales-charts-panel-container.component.html',
  styleUrl: './sales-charts-panel-container.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class SalesChartsPanelContainerComponent {
  userRoles: any;
  constructor(
    private storageService: StorageService,
    private globalService: GlobalService,
    private apiService: ApiService,
    public changeRef: ChangeDetectorRef,
    public component: CmsComponentData<any>
  ) {}
  public role: string = '';
  reportClass = 'reportContainer';
  public report1 = false;
  public powerBiArray: any[] = [];
  report!: pbi.Embed;
  reportConfig: IReportEmbedConfiguration = {
    type: 'report',
    embedUrl: '',
    tokenType: models.TokenType.Embed,
    accessToken: '',
    settings: {
      panes: {
        filters: {
          visible: false,
        },
        pageNavigation: {
          visible: false,
        },
      },
    },
  };

  public componentData: any;
  private unsubscribe$ = new Subject<void>();

  ngOnInit() {
    this.getFromSessionStorage();
    if (this.role === 'SGRE Admin' || this.role === 'Sales Rep') {
      this.fetchPowerBiData();
    }
  }

  fetchPowerBiData() {
    this.globalService.loadingSubject.next(true);
    let cstmData = `chartNames=salesChart`;
    this.apiService
      .getPowerBi(cstmData, {})
      .pipe(
        map((data: any) => {
          this.powerBiArray = data?.powerBIChart;
          if (this.powerBiArray?.length) {
            this.reportConfig = {
              type: 'report',
              embedUrl: this.powerBiArray[0]?.embedUrl?.embedUrl,
              tokenType: models.TokenType.Embed,
              accessToken: this.powerBiArray[0]?.embedToken?.token,
              id: this.powerBiArray[0]?.embedUrl?.reportId,
              settings: {
                panes: {
                  filters: {
                    visible: false,
                  },
                  pageNavigation: {
                    visible: false,
                  },
                },
              },
            };
          }
          this.changeRef.markForCheck();
        }),
        takeUntil(this.unsubscribe$)
      )
      .subscribe({
        next: (data) => {
          this.globalService.loadingSubject.next(false);
        },
        error: (error) => {
          this.globalService.loadingSubject.next(false);
        }
      });
  }

  getFromSessionStorage() {
    let userRole: any = '';
    userRole = this.storageService.userRoles;

    if (userRole?.includes('SGRE Admin')) {
      this.role = 'SGRE Admin';
    } else if (userRole?.includes('Sales Rep')) {
      this.role = 'Sales Rep';
    }
  }

  ngOnDestroy() {
    this.unsubscribe$.next(undefined);
    this.unsubscribe$.complete();
  }
}
